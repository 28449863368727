<template>
  <div>
    <b-container fluid class="content">
      <b-card no-body>
        <b-card-body>
          <b-form>
            <b-row>
              <b-col>
                <base-input :label="$t('From Date')">
                  <b-row>
                    <b-col>
                      <flat-pickr
                        :config="configs.from"
                        class="form-control"
                        :placeholder="$t('From Date') + '...'"
                        v-model="from_date"
                      >
                      </flat-pickr>
                    </b-col>
                    <b-col v-if="!ac_time">
                      <flat-pickr
                        :config="configs.from_t"
                        class="form-control"
                        :placeholder="$t('From Time') + '...'"
                        v-model="from_time"
                      >
                      </flat-pickr>
                    </b-col>
                  </b-row>
                </base-input>
              </b-col>
              <b-col>
                <base-input :label="$t('To Date')">
                  <b-row>
                    <b-col>
                      <flat-pickr
                        :config="configs.to"
                        class="form-control"
                        :placeholder="$t('To Date') + '...'"
                        v-model="to_date"
                      >
                      </flat-pickr>
                    </b-col>
                    <b-col v-if="!ac_time">
                      <flat-pickr
                        :config="configs.to_t"
                        class="form-control"
                        :placeholder="$t('To Time') + '...'"
                        v-model="to_time"
                      >
                      </flat-pickr>
                    </b-col>
                  </b-row>
                </base-input>
              </b-col>
              <b-col>
                <base-input :label="$t('Store')">
                  <select class="form-control" v-model="cur_store_ID" @change="store_changed">
                    <option></option>
                    <option v-for="(loc, idx) in storelist" :key="idx" :value="idx" >
                      {{ loc.LocationName }}
                    </option>
                  </select>
                </base-input>
              </b-col>
              <b-col v-if="computerlist.length > 1" >
                <base-input label="Computer">
                  <select class="form-control" v-model="cur_computer" @change="change_station">
                    <option></option>
                    <option v-for="comp in computerlist" :key="comp.IDn" :value="comp.IDn">
                      {{ comp.LicenseKey.substr(0,4) }} .. {{ comp.LicenseKey.substr(-4) }}
                    </option>
                  </select>
                </base-input>
              </b-col>
              <b-col v-if="showStation" >
                <base-input label="Station">
                  <select class="form-control" v-model="cur_station">
                    <option></option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                  </select>
                </base-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col>  
                <b-form-checkbox
                  class="form-checkbox"
                  v-model="ac_time"
                  :value="true"
                  :unchecked-value="false"
                  @change="ac_time_change"
                  switch
                >
                  AC Time
                </b-form-checkbox>
              </b-col>
              <b-col>
                <b-form-checkbox
                  class="form-checkbox"
                  v-model="summary_only"
                  :value="true"
                  :unchecked-value="false"
                  switch
                >
                  {{ $t("Summary Only") }}
                </b-form-checkbox>
              </b-col>
              <b-col>
                <button class="btn btn-primary" @click.prevent="form_search">
                  {{ $t("Search") }}
                </button>
              </b-col>
              <b-col>
                <div style="display: flex;">
                  <span>
                    <a v-bind:href="pdfBaseUrl + '/Web/Report/pos_day_end_report?' + 
                      'token=' + token + 
                      '&user_id=' + user_id +
                      '&ac_time=' + this.ac_time +
                      '&FromDate=' + (this.ac_time ? this.from_date : this.from_date + ' ' + this.from_time) +
                      '&ToDate=' + (this.ac_time ? this.to_date : this.to_date + ' ' + this.to_time) +
                      '&StoreID=' + this.cur_store_ID +
                      '&SummaryOnly=' + this.summary_only +
                      '&ComputerName=' + this.cur_computer + 
                      '&StationNo=' + this.cur_station
                      " class="btn btn-primary" target="_blank">PDF</a>
                  </span>
                  <span class="ml-6">
                    <xlsx-workbook>
                      <xlsx-sheet
                        :collection="sheet.data"
                        v-for="sheet in sheets"
                        :key="sheet.name"
                        :sheet-name="sheet.name"
                      />
                      <xlsx-download filename="dayend.xlsx">
                        <button class="btn btn-primary">XLSX</button>
                      </xlsx-download>
                    </xlsx-workbook>
                  </span>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </b-card-body>
      </b-card>
    </b-container>
    <b-container fluid>
      <b-card>
        <b-card-body :style="{ overflowX: 'auto' }">
          <div v-bind:style="{ width: report_width }">
            {{
              this.ac_time ?
              from_date + " To " + to_date : 
              from_date + " " + from_time + " To " + to_date + " " + to_time
            }}
          </div>
          <div v-bind:style="{ width: report_width }">
            <template v-for="(list, list_key) in list_data">
              <template v-if="list_key == 'location_name'">
                <b-row v-bind:key="list_key" v-bind:style="{ backgroundColor: 'lightGrey' }">
                  <b-col>
                    <div v-bind:style="markStyleObject">{{ special_mark }}</div>
                  </b-col>
                  <b-col v-for="(col_data, col_key) in list_data.location_name" v-bind:key="col_key">
                    <div v-bind:style="headerStyleObject">{{ col_data }}</div>
                  </b-col>
                </b-row>
              </template>
              <template v-else>
                <b-row
                  v-bind:key="list_key + '_tital'"
                  v-bind:style="{ marginTop: '15px' }"
                >
                  <b-col>
                    <div v-bind:style="titleStyleObject">
                      {{ title_list[list_key] }}
                    </div>
                  </b-col>
                </b-row>
                <b-row v-bind:key="list_key + '_list'"
                  ><!-- v-for='(list, list_key) in list_data' v-bind:key="list_key" -->
                  <b-col>
                    <b-row
                      v-for="(value, key) in list[Object.keys(list)[0]]"
                      v-bind:key="key"
                    >
                      <b-col>
                        <div
                          v-bind:style="nameStyleObject"
                          v-if="String(value.display_name).trim() == ''"
                        >
                          {{ "(NULL)" }}
                        </div>
                        <div v-bind:style="nameStyleObject" v-else>
                          {{ value.display_name }}
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col
                    v-for="(col_data, col_key) in list"
                    v-bind:key="col_key"
                  >
                    <b-row v-for="(value, key) in col_data" v-bind:key="key">
                      <b-col v-if="value.Count == '' || value.Count == 0"
                        ><div :style="{ textAlign: 'right' }">
                          {{ value.Amount }}
                        </div></b-col
                      >
                      <b-col v-else
                        ><div :style="{ textAlign: 'right' }">
                          {{ "(" + value.Count + ")" + value.Amount }}
                        </div></b-col
                      >
                    </b-row>
                  </b-col>
                </b-row>
              </template>
            </template>
          </div>
        </b-card-body>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx";
import { Table, TableColumn, Button, Pagination } from "element-ui";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { getStore, setStore } from "../../util/helper";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Pagination.name]: Pagination,
    [flatPicker.name]: flatPicker,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
  },
  data() {
    return {
      configs: {
        from: {
          allowInput: false,
          enableTime: false,
          noCalendar: false,
          dateFormat: "Y-m-d",
          maxDate: "today",
          time_24hr: true,
        },
        from_t: {
          allowInput: true,
          enableTime: true,
          noCalendar: true,
          dateFormat: "H:i",
          maxDate: "today",
          time_24hr: true,
        },
        to: {
          allowInput: false,
          enableTime: false,
          noCalendar: false,
          dateFormat: "Y-m-d",
          maxDate: "today",
          time_24hr: true,
        },
        to_t: {
          allowInput: true,
          enableTime: true,
          noCalendar: true,
          dateFormat: "H:i",
          maxDate: '23:59',
          time_24hr: true,
        },
      },

      markStyleObject: {
        textAlign: "left",
        fontWeight: "bold",
        fontSize: "20px",
      },
      headerStyleObject: {
        textAlign: "right",
        fontWeight: "bold",
        fontSize: "18px",
      },
      titleStyleObject: {
        textAlign: "left",
        textDecoration: "underline",
        fontWeight: "bold",
        fontSize: "22px",
      },
      nameStyleObject: {
        textAlign: "left",
        fontWeight: "bold",
      },

      token: "",
      user_id: 0,
      only_self: null,

      storelist: null,
      computerlist: [],
      showStation: false,

      list_data: null,
      data_column_count: null,
      title_list: null,
      special_mark: null,

      from_date: null,
      from_time: null,
      to_date: null,
      to_time: null,

      cur_store_ID: null,
      cur_computer: null,
      cur_station: null,

      sheets: [
        { name: "Sheet1", data: [[1, 2, 2], [1, 2, 2, 2], [], [1, 2, 2, 2]] },
      ],

      url: "",

      report_width: null,
      ac_time: true,
      summary_only: true,
    };
  },
  watch: {
    current_page: function (newval, oldval) {
      this.get_list_data();
    },
    cur_store_ID: function (newval, oldval) {
      //this.get_box_list();
      if ((oldval == null || oldval == "") && newval != null && newval != "") {
        var today = new Date();
        if (typeof this.from_date == "undefined" || !this.from_date) {
          this.from_date = today.getFullYear() + "-" + String(today.getMonth() + 1).padStart(2, "0") + "-" + String(today.getDate()).padStart(2, "0");
        }
        if (typeof this.to_date == "undefined" || !this.to_date) {
          this.to_date = today.getFullYear() + "-" + String(today.getMonth() + 1).padStart(2, "0") + "-" + String(today.getDate()).padStart(2, "0");
        }
        if (typeof this.from_time == "undefined" || !this.from_time) {
          this.from_time = "00:00";
        }
        if (typeof this.to_time == "undefined" || !this.to_time) {
          this.to_time = "23:59";
        }
      }
    },
  },
  computed: {
    pdfBaseUrl: function () {
      return axios.defaults.baseURL + "/Api";
    },
  },
  methods: {
    ac_time_change(checked) {
      // console.log("ac_time_change", checked);
      this.from_time = "00:00";
      this.to_time = "23:59";
    },

    fillup_sheet() {
      // Init 2 dimensional array
      var dataarr = [];
      for (var i = 0; i < 50; i++) {
        dataarr[i] = [];
      }

      try {
        // Date line
        dataarr[0][0] = (
          this.ac_time ?
            this.from_date + " To " + this.to_date : 
            this.from_date + " " + this.from_time + " To " + this.to_date + " " + this.to_time);
        
        var x,y,y1,y2;
        y = 1;
        for (var list_key in this.list_data) {
          if (this.list_data.hasOwnProperty(list_key)) {
            var list = this.list_data[list_key]
            if (list_key == 'location_name') {
              x = 0;
              dataarr[y][x++] = this.special_mark;
              for (var col_key in this.list_data.location_name) {
                if (this.list_data.location_name.hasOwnProperty(col_key)) {
                  dataarr[y][x++] = this.list_data.location_name[col_key];
                }      
              }
              y++;
            } else {
              x = 0;
              dataarr[y][0] = this.title_list[list_key];
              y1 = 1;
              for (var key in list[Object.keys(list)[0]]) {
                if (list[Object.keys(list)[0]].hasOwnProperty(key)) {
                  var value = list[Object.keys(list)[0]][key];
                  if (String(value.display_name).trim() == '') {
                    dataarr[y+y1++][x] = "(NULL)";
                  } else {
                    dataarr[y+y1++][x] = value.display_name;
                  }
                }
              }
              y2 = y1;

              for (var col_key in list) {
                if (list.hasOwnProperty(col_key)) {
                  x++;
                  y1 = 1;
                  var col_data = list[col_key];
                  for (var key in col_data) {
                    if (col_data.hasOwnProperty(key)) {
                      var value = col_data[key];
                      if ((value.Count == '') || (value.Count == 0)) {
                        dataarr[y+y1++][x] = value.Amount;
                      } else {
                        dataarr[y+y1++][x] = "(" + value.Count + ")" + value.Amount;
                      }
                    }
                  }
                }
              }
              y += y2;
            }
          }
        }
      } catch (err) {
        // console.log("fillup_sheet err",err);
      }
      // console.log("fillup_sheet",x,y,dataarr);
      var sheetname = "DayEndReport";
      this.sheets = [{ name: sheetname, data: dataarr }];
    },
    async get_list_data() {
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("ac_time", this.ac_time);
      if (this.ac_time) {
        bodyFormData.append("FromDate", this.from_date);
        bodyFormData.append("ToDate", this.to_date);
      } else {
        bodyFormData.append("FromDate", this.from_date + " " + this.from_time);
        bodyFormData.append("ToDate", this.to_date + " " + this.to_time);
      }
      if (this.cur_store_ID != null) {
        bodyFormData.append("StoreID", this.cur_store_ID);
      }
      if (this.summary_only) {
        bodyFormData.append("SummaryOnly", this.summary_only);
      }
      if (this.cur_computer) {
        bodyFormData.append("ComputerName", this.cur_computer);
      }
      if (this.cur_station) {
        bodyFormData.append("StationNo", this.cur_station);
      }

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Pos_day_end_report",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.onlyself = rt.data.onlyself;
              that.storelist = rt.data.storelist;

              that.list_data = rt.data.list_data;
              that.data_column_count = rt.data.data_column_count;
              that.title_list = rt.data.title_list;
              that.special_mark = rt.data.special_mark;
              that.report_width = String((rt.data.data_column_count + 1) * 240) + "px";
              if (that.list_data) {
                that.fillup_sheet();
              }
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              // that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    change_station() {
      this.showStation = false;
      if (this.cur_computer) {
        for (var i=0; i<this.computerlist.length; i++) {
          if (this.cur_computer == this.computerlist[i].IDn) {
            if (parseInt(this.computerlist[i].Device) > 1) {
              this.showStation = true;
            }
          }
        }
      }
    },
    async store_changed() {
      // this will be called only after form is valid. You can do api call here to login
      this.computerlist = [];
      this.showStation = false;
      this.cur_computer = null;
      this.cur_station = null;
      if (!this.cur_store_ID) {
        return;
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("StoreID", this.cur_store_ID);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Pos_day_end_report/get_box_list",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.computerlist = rt.data.computerlist;
            }
          } else if (rt.status == 4) {
            alert(that.$t("No Permission"));
            // that.$router.go(-1);
          } else if (rt.message != undefined) {
            alert(rt.message);
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    form_cancel() {
      this.$router.push("/store");
    },
    async form_search() {
      this.get_list_data();
    },
    get_today() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      return yyyy + "-" + mm + "-" + dd;
    },
    async init_data() {
      this.from_date = this.get_today();
      this.to_date = this.get_today();
      this.from_time = "00:00";
      this.to_time = "23:59";
      this.token = getStore("token");
      this.user_id = getStore("user_id");

      this.get_list_data();
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style>
.content {
  padding-top: 80px;
}
</style>
